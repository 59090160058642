<template>
  <el-breadcrumb separator="/" class="nav-ban">
        <el-breadcrumb-item :to="{ path: 'home' }">{{homeName}}</el-breadcrumb-item>
        <el-breadcrumb-item v-if="$route.meta.fatTitle">{{$route.meta.fatTitle}}</el-breadcrumb-item>
        <el-breadcrumb-item>{{$route.meta.title}}</el-breadcrumb-item>
        <el-breadcrumb-item v-if="$route.query.ltitle">{{$route.query.ltitle}}</el-breadcrumb-item>
      </el-breadcrumb>
</template>

<script>
export default {
  name: "navBread",
  data() {
    return {
      homeName: '主页',
    };
  },
};
</script>

<style lang="scss" scoped>
.nav-ban{
  height: 54px;
  line-height: 54px;
  background: #232323;
  border: 2px solid #161616;
  border-radius: 2px;
  text-indent: 24px;
}
</style>
