import Vue from "vue";
import VueRouter from "vue-router";
import Layout from "../layout";
import store from '../store'

Vue.use(VueRouter);

const routes = [{
        path: "/login",
        name: "login",
        component: () =>
            import ("@/views/login/index.vue"),
        meta: {
            title: "登录",
        },

    }, {
        path: "*",
        name: "404",
        component: () =>
            import ("@/views/test"),
        meta: {
            title: "404",
            keepAlive: true,
        },
    },
    // {
    //     path: "/ganttProduction",
    //     name: "ganttProduction",
    //     component: () =>
    //         import ("@/views/gantt/index"),
    //     meta: {
    //         title: "生产计划",
    //         text: "",
    //         nav: true,
    //         fatTitle: "智能甘特",
    //         keepAlive: true,
    //     },
    // },
    // {
    //     path: "/ganttAssembly",
    //     name: "ganttAssembly",
    //     component: () =>
    //         import ("@/views/gantt/assemble"),
    //     meta: {
    //         title: "装配计划",
    //         text: "",
    //         nav: true,
    //         fatTitle: "智能甘特",
    //         keepAlive: true,
    //     },
    // },
];

export const DynamicRoutesz = [{
    path: "/",
    component: Layout,
    redirect: "home",
    children: [{
            path: "workCalendar",
            name: "workCalendar",
            component: () =>
                import ("@/views/data/work_calendar"),
            meta: {
                title: "工作日历",
                text: "",
                nav: true,
                fatTitle: "数据管理",
                keepAlive: true,
            },
        },
        {
            path: "/editworkCalendar",
            name: "editworkCalendar",
            component: () =>
                import ("@/views/data/work_calendar/edit"),
            meta: {
                title: "工作日历",
                text: true,
                nav: true,
                fatTitle: "数据管理",
                keepAlive: true,
            },
        },
        {
            path: "produceResource",
            name: "produceResource",
            component: () =>
                import ("@/views/data/produce_resource"),
            meta: {
                title: "生产资源",
                text: "",
                nav: true,
                fatTitle: "数据管理",
                keepAlive: true,
            },
        }, {
            path: "editproduceResource",
            name: "editproduceResource",
            component: () =>
                import ("@/views/data/produce_resource/components/edit/productionEquipment.vue"),
            meta: {
                title: "生产资源",
                text: true,
                nav: true,
                fatTitle: "数据管理",
                keepAlive: true,
            },
        },{
            path: "editworker",
            name: "editworker",
            component: () =>
                import ("@/views/data/produce_resource/components/edit/worker.vue"),
            meta: {
                title: "生产资源",
                text: true,
                nav: true,
                fatTitle: "数据管理",
                keepAlive: true,
            },
        },
        {
            path: "editproductData",
            name: "editproductData",
            component: () =>
                import ("@/views/data/product_data/edit"),
            meta: {
                title: "产品数据",
                text: true,
                nav: true,
                fatTitle: "数据管理",
                keepAlive: true,
            },
        }, {
            path: "assemblyEdit",
            name: "assemblyEdit",
            component: () =>
                import ("@/views/data/product_data/assemblyEdit"),
            meta: {
                title: "产品数据",
                text: true,
                nav: true,
                fatTitle: "数据管理",
                keepAlive: true,
            },
        },{
            path: "productData",
            name: "productData",
            component: () =>
                import ("@/views/data/product_data"),
            meta: {
                title: "产品数据",
                text: "",
                nav: true,
                fatTitle: "数据管理",
                keepAlive: true,
            },
        },
        {
            path: "bomManagement",
            name: "bomManagement",
            component: () =>
                import ("@/views/data/bom_management"),
            meta: {
                title: "BOM管理",
                text: "",
                nav: true,
                fatTitle: "数据管理",
                keepAlive: true,
            },
        },
        {
            path: "processManagement",
            name: "processManagement",
            component: () =>
                import ("@/views/data/process_management"),
            meta: {
                title: "工步列表",
                text: "",
                nav: true,
                fatTitle: "数据管理",
                keepAlive: true,
            },
        },
        {
            path: "customerManagement",
            name: "customerManagement",
            component: () =>
                import ("@/views/data/customer_management"),
            meta: {
                title: "客户管理 ",
                text: "",
                nav: true,
                fatTitle: "数据管理",
                keepAlive: true,
            },
        },
        {
            path: "inventoryManagement",
            name: "inventoryManagement",
            component: () =>
                import ("@/views/data/inventory_management"),
            meta: {
                title: "库存管理 ",
                text: "",
                nav: true,
                fatTitle: "数据管理",
                keepAlive: true,
            },
        },
        {
            path: "doc",
            name: "doc",
            component: () =>
                import ("@/views/help/doc.vue"),
            meta: {
                title: "帮助文档",
                keepAlive: true,
            },
        },
        // {
        //     path: "/productionPlanning",
        //     name: "productionPlanning",
        //     component: () =>
        //         import ("@/views/gantt/production_planning"),
        //     meta: {
        //         title: "生产计划",
        //         text: "",
        //         nav: true,
        //         fatTitle: "智能甘特",
        //         keepAlive: true,
        //     },
        // },
        // {
        //     path: "/assemblyPlanning",
        //     name: "assemblyPlanning",
        //     component: () =>
        //         import ("@/views/gantt/assembly_planning"),
        //     meta: {
        //         title: "装配计划",
        //         text: "",
        //         nav: true,
        //         fatTitle: "智能甘特",
        //         keepAlive: true,
        //     },
        // },
        // {
        //     path: "/parameter",
        //     name: "parameter",
        //     component: () =>
        //         import ("@/views/setting/parameter"),
        //     meta: {
        //         title: "参数设置",
        //         text: "",
        //         nav: true,
        //         fatTitle: "设置",
        //         keepAlive: true,
        //     },
        // },
    ]
}]

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
});


router.beforeEach((to, from, next) => {
    const apsToken = store.state.apsToken;
    if (window.location.search && to.path === '/login') {
        store.dispatch('LOGINACT', true).then(() => {
            const menuLists = store.state.menuLists;
            const defaultPath = menuLists[0].children.length > 0 ? menuLists[0].children[0].path : menuLists[0].path;
            next({ path: defaultPath });
        });
    } else if (!apsToken && to.path !== '/login') {
        next('/login');
    } else {
        if (!store.state.userpermission) {
            store.dispatch('LOGINACT').then(() => {
                next(`${window.location.pathname}`);
                // next({...to, replace: true })
            });
        } else {
            if (to.fullPath.indexOf('?') !== -1) {
                localStorage.setItem('fullPath', to.fullPath);
                next();
                return;
            } else if (to.meta.text) {
                localStorage.getItem('fullPath') ? next(`${localStorage.getItem('fullPath')}`) : next();

            } else {
                localStorage.setItem('fullPath', '');
                next();
            }

        }
    }
});

export default router;