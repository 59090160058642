import { get, post } from "../utils/http"
import { baseUrls } from '../utils/urls'
const aps1 = baseUrls.aps1
const aps2 = baseUrls.aps2
//主页获取初始数据
export function getInitialData() {
    return get(`${aps1}/homePage/getInitialData`)
}

// 主资源生产计划表
export function exportSchedulingPlan(serialNumber) {
    return get(`${aps1}/reportform/export/schedulingPlan`, serialNumber)
}

// 订单交货计划表
export function exportWorkOrderDeliveryPlan() {
    return get(`${aps1}/reportform/export/workOrderDeliveryPlan`)
}

// 每日产出统计报表
export function exportDailyOutputStatistics() {
    return get(`${aps1}/reportform/export/dailyOutputStatistics`)
}

// 设备稼动率
export function exportEquipmentPlannedTurnoverRate() {
    return get(`${aps1}/reportform/export/equipmentPlannedTurnoverRate`)
}

// 人员综合效率
export function exportPersonnelEfficiency(params) {
    return get(`${aps1}/reportform/export/staffOPE`, params)
}

// 排程记录

// 异常分析

// 欠料表--导出
export function exportMaterialShortageInfo(serialNumber) {
    return get(`${aps1}/reportform/export/materialShortageInfo`, serialNumber)
}

// 设备保养计划
export function exportEquipmentMaintenancePlan(serialNumber) {
    return get(`${aps1}/reportform/export/equipmentMaintenancePlan`, serialNumber)
}

//login
export function login(param) {
    return post(`${aps2}/admin/apsUser/login`, param)
}

// 欠料分析数据
export function getMaterialShortageData(param) {
    return get(`${aps1}/materialShortage/page`, param)
}
//欠料分析导出
export function exportMaterialShortageData() {
    return get(`${aps1}/reportform/export/materialShortageInfo`)
}