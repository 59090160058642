// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/images/common/logo2.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".header-sider[data-v-5a82cd21]{color:#b9b9b9;height:100%;overflow:hidden;display:flex;justify-content:space-between;align-items:center}.header-sider img[data-v-5a82cd21]{vertical-align:middle}.header-sider .user-info[data-v-5a82cd21]{padding:0 1.04167vw;height:2.60417vw;display:flex;justify-content:flex-end;align-items:center;font-size:.9375vw}.header-sider .user-info .userPic[data-v-5a82cd21]{width:1.66667vw;height:1.66667vw;margin:0 .52083vw}.header-sider .user-info .timeb[data-v-5a82cd21]{font-size:.83333vw!important;margin:0 .52083vw 0 1.5625vw}.header-sider .user-info .times[data-v-5a82cd21]{font-size:1.5625vw;color:#fff}.header-sider .user-info img[data-v-5a82cd21]{cursor:pointer;margin:0 .41667vw;vertical-align:middle}.header-sider .header-menu[data-v-5a82cd21]{color:#fff!important;padding:.52083vw 1.04167vw 0 1.04167vw}.header-sider .header-menu h3[data-v-5a82cd21]{font-size:1.25vw;line-height:1.875vw;margin-right:1.5625vw;color:#e8e8e8}.header-sider .header-menu .menu-info[data-v-5a82cd21]{display:flex}.header-sider .header-menu .menu-info p[data-v-5a82cd21]{margin:-.625vw 0 0 0}.header-sider .box-sider[data-v-5a82cd21]{padding:0 .52083vw;color:#fff;display:flex;flex-wrap:nowrap;justify-content:space-around;align-items:baseline;height:3.33333vw;margin:0 1.04167vw;cursor:pointer;padding-top:1.19792vw}.header-sider .box-sider h2[data-v-5a82cd21]{font-size:1.25vw;color:#2deb79;margin:0 .41667vw}.header-sider .box-sider p[data-v-5a82cd21]{font-size:1.19792vw;white-space:nowrap!important;margin:0 .10417vw}.header-sider .box-sider span[data-v-5a82cd21]{font-size:.83333vw;color:#2deb79;margin-left:.26042vw}.header-sider .box-sider .logo_home2[data-v-5a82cd21]{width:8.59375vw;height:1.51042vw;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat 50%;background-size:contain;margin:.26042vw .41667vw 0 0}.header-sider .logo_home[data-v-5a82cd21]{width:5.88542vw;height:1.97917vw}.text1[data-v-5a82cd21]{color:#fff}", ""]);
// Exports
module.exports = exports;
