const allroutes = [{
        path: "/home",
        name: "home",
        component: () =>
            import ("@/views/home"),
        meta: {
            title: "主页",
            text: "",
            nav: false,
            keepAlive: true,
            icon: require('@/assets/images/menu/menu_home.svg'),
        },
    },
    {
        path: "ganttProduction",
        name: "ganttProduction",
        component: () =>
            import ("@/views/gantt"),
        meta: {
            title: "生产计划",
            text: "",
            nav: true,
            fatTitle: "智能甘特",
            keepAlive: true,
        },
    },
    // {
    //     path: "/ganttAssembly",
    //     name: "ganttAssembly",
    //     component: () =>
    //         import ("@/views/gantt/assemble"),
    //     meta: {
    //         title: "装配计划",
    //         text: "",
    //         nav: true,
    //         fatTitle: "智能甘特",
    //         keepAlive: true,
    //     },
    // },
    {
        path: "orders",
        name: "工单管理",
        component: () =>
            import ("@/views/orders"),
        meta: {
            title: "工单管理",
            nav: true,
            id: '1',
            keepAlive: true,
            icon: require('@/assets/images/menu/menu_order.svg'),

        },
        children: [{
                path: "/production",
                name: "生产工单",
                component: () =>
                    import ("@/views/orders/production"),
                meta: {
                    title: "生产工单",
                    text: "",
                    nav: true,
                    fatTitle: "工单管理",
                    keepAlive: true,
                },
            },
            {
                path: "/sale",
                name: "销售订单",
                component: () =>
                    import ("@/views/orders/sale"),
                meta: {
                    title: "销售订单",
                    text: "",
                    nav: true,
                    fatTitle: "工单管理",
                    keepAlive: true,
                },
            },
            {
                path: "/assemble",
                name: "装配工单",
                component: () =>
                    import ("@/views/orders/assemble"),
                meta: {
                    title: "装配工单",
                    text: "",
                    nav: true,
                    fatTitle: "工单管理",
                    keepAlive: true,
                },
            },
        ]
    },
    {
        path: "plan",
        name: "计划管理",
        component: () =>
            import ("@/views/plan"),
        meta: {
            title: "计划管理",
            nav: true,
            id: '2',
            keepAlive: true,
            icon: require('@/assets/images/menu/menu_plan.svg'),
        },
        children: [{
                path: "/scheduling",
                name: "scheduling",
                component: () =>
                    import ("@/views/plan/scheduling"),
                meta: {
                    title: "排程记录",
                    text: "",
                    nav: true,
                    fatTitle: "计划管理",
                    keepAlive: false,
                },
            },
            {
                path: "/analysis",
                name: "analysis",
                component: () =>
                    import ("@/views/plan/analysis"),
                meta: {
                    title: "计划分析",
                    text: "",
                    nav: true,
                    fatTitle: "计划管理",
                    keepAlive: false,
                },
            },
            {
                path: "/bom",
                name: "bom",
                component: () =>
                    import ("@/views/plan/bom"),
                meta: {
                    title: "齐料分析",
                    text: "",
                    nav: true,
                    fatTitle: "计划管理",
                    keepAlive: true,
                },
            },
        ]
    },
    // add 齐料分析
    {
        path: "homogeneous",
        name: "homogeneous",
        component: () =>
            import ("@/views/homogeneous"),
        meta: {
            title: "齐料分析",
            text: true,
            nav: false,
            keepAlive: false,
            icon: require('@/assets/images/menu/menu_gante.svg'),
        },
        children: [{
                path: "/undermaterial",
                name: "undermaterial",
                component: () =>
                    import ("@/views/homogeneous/undermaterial"),
                meta: {
                    title: "欠料分析",
                    text: "",
                    nav: true,
                    fatTitle: "齐料分析",
                    keepAlive: true,
                },
            },
            {
                path: "/bundle",
                name: "bundle",
                component: () =>
                    import ("@/views/homogeneous/bundle"),
                meta: {
                    title: "齐套分析",
                    text: "",
                    nav: true,
                    fatTitle: "齐料分析",
                    keepAlive: true,
                },
            },
        ]
    },
    //
    {
        path: "data",
        name: "data",
        component: () =>
            import ("@/views/data"),
        meta: {
            title: "数据管理",
            text: "",
            nav: true,
            keepAlive: true,
            icon: require('@/assets/images/menu/menu_data.svg'),
        },
    },
    {
        path: "workCalendar",
        name: "workCalendar",
        component: () =>
            import ("@/views/data/work_calendar"),
        meta: {
            title: "工作日历",
            text: "",
            nav: true,
            fatTitle: "数据管理",
            keepAlive: true,
        },
    },
    {
        path: "/editworkCalendar",
        name: "editworkCalendar",
        component: () =>
            import ("@/views/data/work_calendar/edit"),
        meta: {
            title: "工作日历",
            text: "",
            nav: true,
            fatTitle: "数据管理",
            keepAlive: true,
        },
    },
    {
        path: "produceResource",
        name: "produceResource",
        component: () =>
            import ("@/views/data/produce_resource"),
        meta: {
            title: "生产资源",
            text: "",
            nav: true,
            fatTitle: "数据管理",
            keepAlive: true,
        },
    },
    {
        path: "editproductData",
        name: "editproductData",
        component: () =>
            import ("@/views/data/product_data/edit"),
        meta: {
            title: "产品数据",
            text: "",
            nav: true,
            fatTitle: "数据管理",
            keepAlive: true,
        },
    }, {
        path: "productData",
        name: "productData",
        component: () =>
            import ("@/views/data/product_data"),
        meta: {
            title: "产品数据",
            text: "",
            nav: true,
            fatTitle: "数据管理",
            keepAlive: true,
        },
    },
    {
        path: "bomManagement",
        name: "bomManagement",
        component: () =>
            import ("@/views/data/bom_management"),
        meta: {
            title: "BOM管理",
            text: "",
            nav: true,
            fatTitle: "数据管理",
            keepAlive: true,
        },
    },
    {
        path: "processManagement",
        name: "processManagement",
        component: () =>
            import ("@/views/data/process_management"),
        meta: {
            title: "工步列表",
            text: "",
            nav: true,
            fatTitle: "数据管理",
            keepAlive: true,
        },
    },
    {
        path: "customerManagement",
        name: "customerManagement",
        component: () =>
            import ("@/views/data/customer_management"),
        meta: {
            title: "客户管理 ",
            text: "",
            nav: true,
            fatTitle: "数据管理",
            keepAlive: true,
        },
    },
    {
        path: "inventoryManagement",
        name: "inventoryManagement",
        component: () =>
            import ("@/views/data/inventory_management"),
        meta: {
            title: "库存管理 ",
            text: "",
            nav: true,
            fatTitle: "数据管理",
            keepAlive: true,
        },
    },
    //  智能甘特
    {
        path: "gantt",
        name: "gantt",
        component: () =>
            import ("@/views/gantt"),
        meta: {
            title: "智能甘特",
            text: true,
            nav: false,
            keepAlive: true,
            icon: require('@/assets/images/menu/menu_gante.svg'),
        },
        children: [{
                path: "/ganttProduction",
                name: "ganttProduction",
                component: () =>
                    import ("@/views/gantt/production"),
                meta: {
                    title: "生产计划",
                    text: "",
                    nav: false,
                    fatTitle: "智能甘特",
                    keepAlive: false,
                },
            },
            {
                path: "/ganttAssembly",
                name: "ganttAssembly",
                component: () =>
                    import ("@/views/gantt/assemble"),
                meta: {
                    title: "装配计划",
                    text: "",
                    nav: false,
                    fatTitle: "智能甘特",
                    keepAlive: false,
                },
            },
        ]
    },
    {
        path: "setting",
        name: "设置",
        component: () =>
            import ("@/views/setting"),
        meta: {
            title: "设置",
            nav: true,
            id: '3',
            keepAlive: true,
            icon: require('@/assets/images/menu/menu_plan.svg'),
        },
        children: [{
                path: "/user",
                name: "user",
                component: () =>
                    import ("@/views/setting/user"),
                meta: {
                    title: "用户管理",
                    text: "",
                    nav: true,
                    fatTitle: "设置",
                    keepAlive: true,
                },
            },
            {
                path: "/role",
                name: "role",
                component: () =>
                    import ("@/views/setting/role"),
                meta: {
                    title: "角色管理",
                    text: "",
                    nav: true,
                    fatTitle: "设置",
                    keepAlive: true,
                },
            },
            {
                path: "/parameter",
                name: "parameter",
                component: () =>
                    import ("@/views/setting/parameter"),
                meta: {
                    title: "参数设置",
                    text: "",
                    nav: true,
                    fatTitle: "设置",
                    keepAlive: true,
                },
            },
        ]
    },

    {
        path: "/logs",
        name: "logs",
        component: () =>
            import ("@/views/logs"),
        meta: {
            title: "日志",
            text: "",
            nav: true,
            keepAlive: true,
            icon: require('@/assets/images/menu/menu_logs.svg'),
        },
    },
    {
        path: "help",
        name: "help",
        component: () =>
            import ("@/views/help"),
        meta: {
            title: "帮助",
            text: "",
            nav: true,
            keepAlive: true,
            icon: require('@/assets/images/menu/menu_help.svg'),
        },
    },

];

export default allroutes;